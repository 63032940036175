.contactConent {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  grid-area: r-line1 / c-line1 / r-line3 / c-line3;
  height: 85vh;
  background-color: #cccccc !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.contactHalf {
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contactText1 {
  color: #ffcf40;
  letter-spacing: 0.15em;
}
.contactText2 {
  color: #000000;
  letter-spacing: 0.25em;
  margin-top: 10%;
  margin-bottom: 5%;
}
.contactText3 {
  color: #000000;
  letter-spacing: "0.15em";
}
