.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footerMenu {
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: center;
  align-content: flex-start;
}
.footerMenuButtons {
  text-transform: none !important;
  justify-content: flex-start !important;
  font-size: 0.8rem !important;
  color: #4e4e4e !important;
  letter-spacing: 0.3rem !important;
}
.footerIcons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 10vh !important;
  justify-content: center;
  align-content: center;
}
.footerSvgIcon {
  fill: black !important;
}
.footerLeft {
  margin-left: 5% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  width: 40vh;
}
.footerMadeBy {
  display: flex;
  flex-direction: row;
  color: black;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.5rem !important;
  text-align: right;
  width: 100%;
}
.footerInfo {
  margin-right: 5% !important;
  display: flex;
  flex-direction: column;
}
.footerContactInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footerIntelliText {
  margin-bottom: 0.5rem;
  color: #2f2f2f;
  letter-spacing: 0.25em;
}
