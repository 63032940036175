.page4Content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-area: r-line5 / c-line1 / r-line6 / c-line3;
  height: 100vh;
  background-color: #212121 !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.page4Half {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  /* gap: 5%; */
}
.page4Line {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 25%;
  align-items: center;
}
.page4Header1 {
  color: #ffcf40;
  letter-spacing: 0.15em;
  margin-right: 10% !important;
  margin-left: 10% !important;
  width: 40%;
  text-align: left;
}
.page4Text1 {
  color: #ffffff;
  letter-spacing: 0.05em;
  text-align: left;
  margin-left: 5% !important;
  width: 60%;
}
.page4HorizontalLine {
  width: calc(100% - 2px);
  border: 1px solid #fdd04c;
  background: #fdd04c;
  height: 1px;
}
