.page3Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-area: r-line4 / c-line1 / r-line5 / c-line3;
  background: rgba(128, 128, 128, 0) !important;
  height: 100vh;
}
.page3Header1 {
  color: #ffa033;
  letter-spacing: 0.15em;
  margin-left: 10% !important;
  margin-right: 10% !important;
}
.page3Text1 {
  color: #000000;
  letter-spacing: 0.1em;
  margin-left: 10% !important;
  margin-right: 10% !important;
}
.page3HorizontalLineLeft {
  position: absolute;
  width: 50%;
  border: 1px solid #fdd04c;
  background: #fdd04c;
  height: 1px;
  left: 10%;
}
.page3HorizontalLineRight {
  position: absolute;
  width: 50%;
  border: 1px solid #fdd04c;
  background: #fdd04c;
  height: 1px;
  left: 40%;
}
