.page1Content {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  grid-area: r-line1 / c-line1 / r-line3 / c-line3;
  height: 100vh;
  background-color: #cccccc !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.page1Half {
  height: 100vh;
  width: 50%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.page1Header1 {
  color: #ffa033;
  letter-spacing: 0.3em;
  margin-left: 10% !important;
}
.page1Header2 {
  color: #ffa033;
  letter-spacing: 0.15em;
  margin-left: 10% !important;
}
