.page3Content {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  grid-area: r-line4 / c-line1 / r-line5 / c-line3;
  background: rgba(128, 128, 128, 0) !important;
  height: 100vh;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
    0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0) !important;
}
.page3Half {
  height: 75%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 5%;
  max-height: 100vh;
}
.page3Div {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  margin-right: 1%;
}
.page3Header1 {
  color: #ffa033;
  text-align: left;
  letter-spacing: 0.1em;
  margin-left: 75px !important;
  width: 100%;
}
.page3Header2 {
  color: #ffa033;
  letter-spacing: 0.15em;
  margin-left: 1% !important;
  text-align: left;
}
.page3Text1 {
  color: #000000;
  letter-spacing: 0.05em;
  text-align: left;
}
