.homeServicesApp {
  display: grid;
  grid-template-columns: [c-line1] 50% [c-line2] 50% [c-line3];
  grid-template-rows: [r-line1] 25vh [r-line2] 75vh [r-line3] 100vh [r-line4] 100vh [r-line5] 100vh [r-line6] 100vh [r-line7] 85vh [r-line8] 15vh [r-line9];
  background: rgba(128, 128, 128, 0) !important;
  text-align: center;
}
.homeServicesFooter {
  display: flex;
  background-color: white !important;
  align-items: flex-start;
  justify-content: flex-end;
  font-weight: bold;
  grid-area: r-line8 / c-line1 / r-line9 / c-line3;
  min-height: 15vh;
  width: 100%;
  flex-direction: column;
}
