.page2Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  grid-area: r-line3 / c-line1 / r-line4 / c-line3;
  height: 100vh;
  background-color: #cccccc !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.page2Header1 {
  color: #ffa033;
  letter-spacing: 0.3em;
  margin-left: 10% !important;
  text-align: left;
}
.page2Header2 {
  color: #ffa033;
  letter-spacing: 0.15em;
  margin-left: 10% !important;
  text-align: left;
}
.page2Text1 {
  color: #ffffff;
  letter-spacing: 0.1em;
  width: 40%;
  margin-top: 5% !important;
  margin-left: 10% !important;
  margin-right: 10% !important;
  text-align: left;
}
