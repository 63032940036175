.page3Content1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-area: r-line4 / c-line1 / r-line5 / c-line3;
  background: rgba(128, 128, 128, 0) !important;
  height: 60vh;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
    0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0) !important;
}
.page3Content2 {
  grid-area: r-line5 / c-line1 / r-line6 / c-line3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25vh;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
    0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0) !important;
}
.page3Half {
  background-color: #cccccc;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.page3Button {
  border-radius: 30px !important;
  border-color: #ffa033 !important;
  background: #ffa033 !important;
}
.page3Header1 {
  color: #ffa033;
  letter-spacing: 0.15em;
  margin-top: 10% !important;
  text-align: left;
}
.page3Text1 {
  color: #4e4e4e;
  letter-spacing: 0.15em;
  text-align: left;
  margin-left: 10% !important;
  margin-right: 5% !important;
}
.page3Text2 {
  color: #ffcf40;
  letter-spacing: 0.15em;
  text-align: left;
  margin-left: 10% !important;
  margin-right: 5% !important;
}
.page3ButtonText {
  color: #ffffff;
  letter-spacing: 0.2em;
}
.page3ContactText {
  color: #000000;
  letter-spacing: 0.15em;
}
