.page5Content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-area: r-line6 / c-line1 / r-line7 / c-line3;
  background: rgba(128, 128, 128, 0) !important;
  height: 100vh;
}
.page5Half {
  background-color: #cccccc;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.page5Right {
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.page5Header1 {
  color: #ffa033;
  text-align: left;
  letter-spacing: 0.05em;
  margin-left: 10% !important;
}
.page5Text1 {
  color: #484848;
  letter-spacing: 0.05em;
  margin-top: 5% !important;
  margin-bottom: 5% !important;
  margin-left: 10% !important;
  margin-right: 10% !important;
  text-align: left;
}
.page1VerticalLine {
  height: 100vh;
  border: 1px solid #ffa033;
  background: #ffa033;
  width: 3px;
}
.page1HorizontalLine {
  width: calc(100% - 2px);
  border: 1px solid #ffa033;
  background: #ffa033;
  height: 1px;
}
